<template>
  <div>
    <div
      v-if="subTitle!==''"
      class="trsText--text text-h5 font-weight-medium"
    >
      {{ subTitle }}
    </div>
    <div
      v-if="uploadedDocuments.length>0"
      class="my-2"
    >
      <ul>
        <li
          v-for="item of uploadedDocuments"
          :key="item.id"
        >
          <div>
            <v-chip
              medium
              label
              :close="deleteAccess"
              @click:close="deleteConfirmModal(item)"
            >
              {{ item.name }}
              <a
                :href="item.url"
                @click.prevent="downloadItem(item)"
              > <v-icon class="mx-1">mdi-download</v-icon>
              </a>
            </v-chip>
          </div>
        </li>
      </ul>
    </div>
    <div v-else>
      No documents
    </div>
    <generic-delete-modal
      v-model="docDeleteModal"
      @yes="deleteDoc"
      @close="closeModal"
    />
  </div>
</template>
<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import GenericDeleteModal from './GenericDeleteModal'
  export default {
    name: 'ViewDocuments',

    components: {
      GenericDeleteModal,
    },

    props: {
      id: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: '',
      },
      documentsHandler: {
        type: Boolean,
        default: false,
      },
      subTitle: {
        type: String,
        default: '',
        required: false,
      },
    },

    data: () => ({
      uploadedDocuments: [],
      docDeleteModal: false,
      deleteAccess: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      isHolder () {
        return this.$store.getters['auth/roles'].includes(this.CONSTS.USER_ROLES.HOLDER)
      },
    },

    watch: {
      documentsHandler: {
        handler (val) {
          if (val) {
            this.getDocuments(this.id)
          }
        },
      },
    },

    mounted () {
      this.getDocuments()
    },

    methods: {
      async getDocuments () {
        try {
          const resp = await captableService.getDocuments(this.captableId, this.type, this.id)
          this.uploadedDocuments = resp.data.documents
          this.removeNonViewableDocs()
        } catch (err) {}
      },
      deleteConfirmModal (document) {
        this.docDeleteModal = true
        this.docId = document.id
      },
      async deleteDoc () {
        try {
          await captableService.deleteDocuments(this.docId)
          this.docDeleteModal = false
          this.$store.dispatch('app/message', { text: 'Document deleted successfully.' })
          this.getDocuments()
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to delete document. Please try again later.',
          })
        }
      },
      closeModal () {
        this.docDeleteModal = false
      },
      base64ToArrayBuffer (base64) {
        const binaryString = window.atob(base64)
        const binaryLen = binaryString.length
        const bytes = new Uint8Array(binaryLen)
        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i)
          bytes[i] = ascii
        }
        return bytes
      },
      async downloadItem (doc) {
        const label = doc.name
        try {
          const resp = await captableService.downloadDoc(doc.id)
          const base64ToBytes = this.base64ToArrayBuffer(resp.data.file)
          const blob = new Blob([base64ToBytes], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to download document. Please try again later.',
          })
        }
      },
      removeNonViewableDocs () {
        if (this.isHolder) {
          const indxArr = []
          for (const i in this.uploadedDocuments) {
            const doc = this.uploadedDocuments[i]
            if (doc.name.indexOf('certificate') > -1) {
              indxArr.push(i)
            }
          }
          indxArr.forEach((i) => {
            this.uploadedDocuments.splice(i, 1)
          })
        }
      },
    },
  }
</script>

<style scoped>
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .info-container{
    border:1px solid #e5e5e5;
    border-radius: 4px;
    padding: 12px;
  }
  .header-container{
    background-color: rgba(190, 206, 234, 0.35);
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left !important;
  }

  li div {
    display: block;
    text-align: center;
    padding: 4px;
    text-decoration: none;
  }
</style>
