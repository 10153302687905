<template>
  <common-trs-page-container :padding="0">
    <div
      class="d-flex"
    >
      <common-trs-card class="mt-n4 pb-0 mb-2">
        <common-trs-page-nav-vertical
          v-model="currentTab"
        >
          <v-tab
            key="vestingSchedule"
          >
            Vesting Schedule
          </v-tab>
        </common-trs-page-nav-vertical>
      </common-trs-card>
      <div class="flex-fill mx-6 mx-md-10 overflow-auto">
        <v-tabs-items
          v-model="currentTab"
          class="transparent-bg"
        >
          <v-tab-item
            key="vestingSchedule"
            :eager="true"
          >
            <list-vesting-schedule />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </common-trs-page-container>
</template>

<script>
  import ListVestingSchedule from './vestingSchedule/ListVestingSchedule'

  export default {
    name: 'ManageSecuritiesIndex',

    components: {
      ListVestingSchedule,
    },

    data: () => ({
      currentTab: 0,
    }),
  }
</script>

<style lang="scss" scoped>
  .v-tabs.v-tabs--vertical {
    min-width: 225px !important;
    height: 100%;
  }
  ::v-deep .v-tabs-items {
    background-color: transparent !important;
  }
  .v-tabs {
    padding-top: 20px!important;
    .v-tab {
      height: 41px;
      font-size: 0.875rem !important;
      font-weight: 400;
      margin: 5px 0px;
      &:hover {
        color: var(--v-primary-base) !important;
        background-color: rgba(0, 159, 70, 0.1)!important;
      }
      &.v-tab--active {
        font-weight: 600;
        background-color: rgba(0, 159, 70, 0.1)!important;
      }
    }
  }
</style>
