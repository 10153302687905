<template>
  <div>
    <div
      v-if="title!==''"
      class="trsText--text text-h5 font-weight-medium"
    >
      {{ title }}
    </div>
    <div v-if="notes !== ''">
      {{ notes }}
    </div>
    <div v-else>
      No notes
    </div>
  </div>
</template>
<script>
  import * as captableService from '@/components/common/captable/services/captable'
  export default {
    name: 'ViewNotes',

    props: {
      id: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: '',
      },
      notesHandler: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
        required: false,
      },
    },

    data: () => ({
      notes: '',
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      notesHandler: {
        handler (val) {
          if (val) {
            this.getNotes()
          }
        },
      },
    },

    mounted () {
      this.getNotes()
    },

    methods: {
      async getNotes () {
        try {
          const resp = await captableService.getNote(this.captableId, this.type, this.id)
          this.notes = resp.data.value.notes
        } catch (err) {}
      },
    },
  }
</script>

<style scoped>
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .info-container{
    border:1px solid #e5e5e5;
    border-radius: 4px;
    padding: 12px;
  }
  .header-container{
    background-color: rgba(190, 206, 234, 0.35);
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left !important;
  }

  li div {
    display: block;
    text-align: center;
    padding: 4px;
    text-decoration: none;
  }
</style>
