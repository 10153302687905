<template>
  <div>
    <v-card
      class="card-item mt-6 pa-0"
    >
      <div class="trsBack pa-4">
        <div class="d-flex justify-space-between align-center">
          <div
            class="text-h4 font-weight-medium primary--text text-capitalize d-flex align-center"
          >
            <slot name="card-title" />
          </div>
          <div
            v-if="showCreatedDate"
            class="trsText--text font-weight-medium text-body-2 text--lighten-1"
          >
            Created On: <slot name="created-on-date" />
          </div>
        </div>

        <div
          class="d-flex justify-space-between align-center"
        >
          <div class="d-flex justify-space-between align-center">
            <div class="trsText--text font-weight-medium text-body-2 mr-2">
              <slot name="card-caption-title" />
            </div>
            <div class="trsText--text text-body-2 font-weight-bold">
              <slot name="card-caption-data" />
            </div>
            <div>
              <slot name="custom-card-caption" />
            </div>
          </div>
          <div class="d-flex justify-space-between align-center">
            <div>
              <common-trs-btn
                v-if="isEditable"
                type="tertiary"
                class="mx-1"
                icon
                color="primary"
                v-on="{...tooltip }"
                @click="editSecurities"
              >
                <v-icon>mdi-pencil</v-icon>
              </common-trs-btn>
            </div>
            <div>
              <common-app-confirm
                v-if="isDeleteable"
                :title="deleteTitle"
                :message="deleteMessage"
                :note="deleteNote"
                yes-button="Yes"
                no-button="Cancel"
                @yes="deleteSecurities"
              >
                <template v-slot:activator="{ on }">
                  <common-trs-btn
                    type="tertiary"
                    color="primary"
                    icon
                    text
                    v-on="on"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </common-trs-btn>
                </template>
              </common-app-confirm>
            </div>
          </div>
        </div>
      </div>

      <v-divider />
      <div class="px-4 pt-4">
        <v-row dense>
          <!-- Card Details Section -->
          <v-col
            v-for="(obj, key1) of details"
            :key="key1"
            cols="12"
            class="pt-0"
          >
            <div
              class="request mb-2"
            >
              <div
                class="trsText--text text-body-3 font-weight-medium text-capitalize text--lighten-2 mb-1"
              >
                {{ obj.label }}
              </div>
              <v-row
                class="trsText--text font-weight-light text-body-1 text--lighten-2 mb-0 mt-0 "
              >
                <v-col
                  v-for="(item, key2) of obj.data"
                  :key="key2"
                  cols="12"
                  :lg="checkIsEvenData(obj.data.length) ? '6' : '12'"
                  :md="checkIsEvenData(obj.data.length) ? '6' : '12'"
                  :sm="checkIsEvenData(obj.data.length) ? '6' : '12'"
                  class="py-1"
                >
                  <span class="text-capitalize">
                    {{ item.title }}:
                  </span>
                  <span class="trsText--text text-body-3 font-weight-medium text-capitalize">
                    {{ item.value }}
                  </span>
                  <span
                    v-if="item.extraTitle"
                    class="text-capitalize mr-1"
                  >
                    / {{ item.extraTitle }}:
                  </span>
                  <span
                    v-if="item.extraValue"
                    class="trsText--text text-body-3 font-weight-medium text-capitalize"
                  >
                    {{ item.extraValue }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <v-divider
              v-if="key1 < details.length - 1"
              class="mb-2 mt-5"
            />
          </v-col>
          <!-- Sub-Card Section -->
          <v-col cols="12">
            <slot name="sub-cards" />
          </v-col>
        </v-row>
        <v-row
          v-if="itemId"
          class="my-0"
        >
          <v-col class="info-container">
            <div class="my-2">
              <div class="trsText--text text-body-3 font-weight-medium text-capitalize text--lighten-2">
                Documents
              </div>
              <view-documents
                :id="itemId"
                :type="itemType"
                :title="'Documents'"
                :documents-handler="getDocuments"
              />
            </div>
            <v-divider />
            <div class="my-2">
              <div class="trsText--text text-body-3 font-weight-medium text-capitalize text--lighten-2">
                Notes
              </div>
              <view-notes
                :id="itemId"
                :type="itemType"
                :notes-handler="reload"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
  import ViewDocuments from '@/components/common/captable/components/ViewDocuments'
  import ViewNotes from '@/components/common/captable/components/ViewNotes'

  export default {
    name: 'CardView',

    components: {
      ViewDocuments,
      ViewNotes,
    },

    props: {
      details: {
        type: Array,
        default: () => [],
      },
      isEditable: {
        type: Boolean,
        default: false,
      },
      isDeleteable: {
        type: Boolean,
        default: false,
      },
      deleteTitle: {
        type: String,
        required: false,
      },
      deleteMessage: {
        type: String,
        required: false,
      },
      deleteNote: {
        type: String,
        required: false,
      },
      showCreatedDate: {
        type: Boolean,
        default: true,
      },
      reload: {
        type: Boolean,
        default: false,
        required: false,
      },
    },

    data: () => ({
      getDocuments: false,
      itemId: null,
      itemType: null,
    }),
    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      details: {
        handler (val) {
          if (val && val.length > 0) {
            this.itemId = val[0].id
            this.itemType = val[0].type
          }
        },
        immediate: true,
      },
      reload: {
        handler (val) {
          if (val) {
            this.updateDocumentFlag()
          }
        },
        immediate: true,
      },
    },

    mounted () {
      this.updateDocumentFlag()
    },
    methods: {
      editSecurities () {
        this.$emit('edit')
      },
      deleteSecurities () {
        this.$emit('delete', this.item)
      },
      updateDocumentFlag () {
        this.getDocuments = true
        setTimeout(() => {
          this.getDocuments = false
        })
      },
      checkIsEvenData (count) {
        if (count % 2 === 0) {
          return true
        } else {
          return false
        }
      },
    },
  }
</script>
