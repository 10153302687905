<template>
  <v-dialog
    v-model="internalValue"
    :max-width="500"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>
    <validation-observer
      v-slot="{ valid }"
    >
      <common-trs-modal-card>
        <common-trs-modal-card-title>
          <common-trs-modal-card-title-content>
            Confirm
          </common-trs-modal-card-title-content>

          <common-trs-btn
            aria-label="Close"
            type="secondary"
            class="rounded-0"
            color="primary"
            size="large"
            icon
            text
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </common-trs-btn>
        </common-trs-modal-card-title>
        <common-trs-modal-card-text>
          <div class="text-center ma-3">
            <div
              style="word-break: break-word;"
            >
              Do you want to delete this document?
            </div>
          </div>
        </common-trs-modal-card-text>
        <common-trs-modal-card-actions>
          <common-trs-btn
            class="mr-3 primary--text"
            type="tertiary"
            text
            @click="close"
          >
            No
          </common-trs-btn>

          <common-trs-btn
            :disabled="!valid"
            type="primary"
            text
            class="white--text"
            @click="onYes"
          >
            Yes
          </common-trs-btn>
        </common-trs-modal-card-actions>
      </common-trs-modal-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      internalValue: {
        get () {
          return this.value || false
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    methods: {
      close (ev) {
        this.internalValue = false
        this.$emit('close', ev)
      },
      async onYes () {
        this.internalValue = false
        this.$emit('yes')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-dialog .v-card .v-card__title .v-icon.header-icon {
    top: 0px;
    left: 0px;
  }
  .v-dialog__content {
    z-index: 9999999 !important;
  }
</style>
