<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <!-- TODO:: Create new vesting scedule -->
      <v-row>
        <common-trs-btn
          v-if="vestingScheduleData.length>0"
          v-feature="CONSTS.CAPTABLE_FEATURES.CREATE_VESTING_SCHEDULE"
          type="primary"
          large
          class="white--text ml-auto mr-2"
          text
          :to="NewVestingSchedule"
        >
          <v-icon class="mr-2">
            mdi-plus-circle
          </v-icon>
          Create Template
        </common-trs-btn>
      </v-row>
      <common-page-loader
        v-if="loading"
      />

      <card-view
        v-for="(item, key) of vestingScheduleData"
        :key="key"
        :details="item.details"
        :delete-title="'Delete Vesting Schedule'"
        :delete-message="'Are you sure you want to delete this vesting schedule?'"
        :is-editable="isEditAllowed && !item.is_published"
        :is-deleteable="isDeleteAllowed"
        @edit="editVestingSchedule(item)"
        @delete="removeItem(item)"
      >
        <template v-slot:card-title>
          {{ item.vesting_schedule.name }}
        </template>
        <template v-slot:card-caption-title>
          Vesting Type:
        </template>
        <template
          v-slot:card-caption-data
        >
          <span class="text-capitalize">{{ vestingType(item) }}</span>
        </template>

        <template v-slot:created-on-date>
          {{ $date(item.inserted_at) }}
        </template>
      </card-view>

      <no-data-msg
        v-if="vestingScheduleData.length===0 && !loading"
        :route-detail="isCreateAllowed ? NewVestingSchedule : null"
      >
        <template v-slot:msg>
          No vesting schedule found.
        </template>
        <template v-slot:action-btn-label>
          Create a Vesting Schedule
        </template>
        <template v-slot:tooltip-msg>
          Create New Vesting Schedule
        </template>
      </no-data-msg>
    </template>
  </common-trs-page-container>
</template>
<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import CardView from '@/components/common/captable/components/CardView'
  import NoDataMsg from '@/components/common/captable/components/NoDataMsg'
  import { getFeaturePermission } from '@/services/utils'

  export default {
    name: 'VestingSchedule',

    components: {
      CardView,
      NoDataMsg,
    },
    data: () => ({
      vestingScheduleData: [],
      shareTermList: [],
      loading: false,
      NewVestingSchedule: { name: 'NewVestingSchedule' },
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      currentRoles () {
        return this.$store.getters['auth/roles']
      },
      isEditAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.EDIT_VESTING_SCHEDULE, this.currentRoles)
      },
      isDeleteAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.DELETE_VESTING_SCHEDULE, this.currentRoles)
      },
      isCreateAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.CREATE_VESTING_SCHEDULE, this.currentRoles)
      },
    },

    watch: {
      vestingScheduleData: {
        handler (val) {
          if (val) {
            this.updateSummaryData(val)
          }
        },
        immediate: true,
      },
    },

    mounted () {
      this.loadData()
    },

    methods: {
      async loadData () {
        try {
          this.loading = true
          const resp = await captableService.getVesingScheduleList(this.captableId)
          if (resp && resp.data) {
            this.vestingScheduleData = resp.data.vesting_schedules
          }
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },
      updateSummaryData (data) {
        data.forEach((item) => {
          if (item.vesting_schedule.custom.length > 0) {
            const temp = []
            item.vesting_schedule.custom.forEach((v) => {
              temp.push({
                title: 'Vest',
                value: `${(v.percent * 100).toFixed(2)} % vests on ${this.$date(v.vests_on)}`,
                extraTitle: 'Reason',
                extraValue: `${v.reason}`,
              })
            })
            item.details = [{ label: 'summary', data: [...temp] }]
          } else {
            if (item.vesting_schedule.time_based.cliff) {
              item.details = [{
                label: 'Summary',
                data: [{
                  title: 'Schedule',
                  value: `vested ${item.vesting_schedule.time_based.vesting_period} (${item.vesting_schedule.time_based.vesting_term} ${this.vestingPeriod(item).text})`,
                }, {
                  title: 'Cliff Vesting',
                  value: `${(item.vesting_schedule.time_based.cliff.percent) * 100} % after ${item.vesting_schedule.time_based.cliff.duration} ${this.vestingPeriod(item).text}`,
                }],
              }]
            } else {
              item.details = [{
                label: 'Summary',
                data: [{
                  title: 'Schedule',
                  value: `vested ${item.vesting_schedule.time_based.vesting_period} (${item.vesting_schedule.time_based.vesting_term} ${this.vestingPeriod(item).text} )`,
                }],
              }]
            }
          }
        })
      },

      // TODO:: Edit vesting schedule
      editVestingSchedule (item) {
        const route = { name: 'EditVestingSchedule', query: { id: item.id } }
        this.$router.push(route)
      },

      vestingType (item) {
        return item.vesting_schedule.custom.length > 0 ? this.CONSTS.VESTING_TYPE.CUSTOM : this.CONSTS.VESTING_TYPE.TIME_BASED
      },
      vestingPeriod (item) {
        return this.CONSTS.VESTING_PERIOD_TYPE.find(o => o.value === item.vesting_schedule.time_based.vesting_period)
      },

      async removeItem (itemData) {
        try {
          this.loading = true
          await captableService.deleteVestingScheduleById(this.captableId, itemData.id)
          this.loading = false
          this.loadData()
          this.$store.dispatch('app/message', { text: 'Vesting schedule deleted successfully.' })
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },
    },
  }
</script>
