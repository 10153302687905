<template>
  <div
    class="no-activity content-box custom-scroll-1 text-center"
  >
    <div
      class="trsText--text text-h3 text--lighten-1 grey--text text-center no-activity-error"
    >
      <slot name="msg" />
    </div>

    <v-tooltip
      v-if="routeDetail"
      bottom
    >
      <template v-slot:activator="{ on: tooltip }">
        <common-trs-btn
          :to="routeDetail"
          type="primary"
          v-on="{ ...tooltip }"
        >
          <v-icon class="mr-2">
            mdi-plus-circle-outline
          </v-icon>
          <slot name="action-btn-label" />
        </common-trs-btn>
      </template>
      <span>
        <slot name="tooltip-msg" />
      </span>
    </v-tooltip>
  </div>
</template>
<script>
  export default {
    name: 'NoDataMsg',
    props: {
      routeDetail: {
        type: Object,
        required: false,
      },
    },
  }
</script>
<style lang="scss" scoped>
 .content-box {
    height: 400px;
    overflow: auto;
    padding: 2px 10px;

    .v-btn-dashed {
      background-color: #f1f1f1 !important;
      text-transform: none;
      color: #b4b4b4 !important;
      border: 1px dashed #b4b4b4 !important;
  }
  }
  .content-box.no-activity {
    font-size: 18px;
    padding: 80px 40px;
    .no-activity-error {
      margin: 20px;
    }
  }
</style>
